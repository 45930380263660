<template>
  <div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <el-form-item prop="jobNo">
            <el-input v-model="loginForm.jobNo" type="text" auto-complete="off" placeholder="请输入教师工号" ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            :type="inputType"
            auto-complete="off"
            placeholder="请输入密码"
            @focus="onFocus"
            @keyup.enter.native="handleLogin"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button :loading="loading" class="login-btn" @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
        <el-form-item>
            <div class="forget">
              <span class="msg" @click="resetPassword">重置密码</span>
            </div>
        </el-form-item>
      </el-form>
  </div>
</template>
<script>
const sha512 = require("js-sha512");
import { Message } from 'element-ui';
import { mapActions } from 'vuex';
import { page } from './../../../api/index.js';
export default {
  name: "login",
  data() {
    return {
      buttonType: true,
      dialogVisible: true,
      inputType: 'text',
      loginForm: {
        jobNo: "",
        password: "",
        phoneNo: "",
      },
      loginRules: {
        jobNo: [{ required: true, trigger: "blur", message: "教师工号不能为空" }],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
      },
      loading: false,
      tipDialogVisible: false,
    };
  },
  created() {
    this.$store.commit('logout');
  },
  methods: {
    ...mapActions(['logout']),
    onFocus(){
      this.inputType = 'password'
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          localStorage.clear();
          let timestamp = new Date().getTime();
          let pwd1 = sha512(`${this.loginForm.password}:yzyx`);
          let pwd2 = sha512(`${pwd1}${timestamp}`);
          this.$store.dispatch("login", {
              jobNo: this.loginForm.jobNo,
              pwd: pwd2,
              timestamp: timestamp,
            })
            .then((defRole) => {
              console.log('登录成功！！！！')
              if(defRole.type){
                this.loading = false;
                this.$emit('setDialogVisible',true);
              }else{
                page.getCoursePaikeId('', res => {
                  if(res.status === 1) {
                      if(res.data && res.data.courseName){
                          this.$store.commit('SETCOURSENAME', res.data.courseName);
                          localStorage.courseName = res.data.courseName;
                          this.$store.commit('SETCLASSCODE', res.data.classCode);
                          localStorage.classCode = res.data.classCode;
                          this.$store.commit('SETCURRENTTIME', res.data.currentTime);
                          localStorage.currentTime = res.data.currentTime;
                          this.$router.push({
                            path: "home",
                        });
                      }else{
                        this.$store.commit('SETCURRENTTIME', res.data);
                        localStorage.currentTime = res.data;
                        this.$router.push({
                            path: "home",
                        });
                      }
                  }else {
                      this.$router.push({
                            path: "home",
                      });
                      Message.error(res.message);
                  }
                });
              }
            })
            .catch((err) => {
              this.loading = false;
              if(err.status === 10004){
                 this.$emit('setPassWordDialogVisible',true);
              }else{
                 Message.error(err.message);
              }
            });
          }
      });
    },
    resetPassword(){
      this.$emit('setTipDialogVisible',true);
    },
  },
};
</script>

<style lang="less" scoped>
form {
      position: absolute;
      top: 96px;
      left: 171px;
      .login-btn {
        margin-top: 10px;
        font-size: 30px;
        color: #ffffff;
        width: 549px;
        height: 95px;
        padding: 0;
        border-radius: 48px;
        background: url("../../../assets/image/btn-login.png") center -5px no-repeat;
        background-size: cover;
        border: none;
      }
    }
  .el-form-item {
    margin-bottom: 36px;
    width: 100%;
    width: 549px;
    /deep/.el-input {
      .el-input__inner {
        height: 95px;
        line-height: 95px;
        border-radius: 48px;
        padding-left: 50px;
        font-size: 24px;
        // padding: 0 15px 0 40px;
         &::placeholder {
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #939393;
        }
      }
    }
    /deep/ .el-form-item__error{
      font-size: 20px;
      left: 50px
    }
  }
  .forget{
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    color: #fff;
    display: flex;
    justify-content: center;
    .msg{
      cursor: pointer;
    }
  }
  /deep/ .el-dialog__headerbtn{
    display: none;
  }
</style>
