<template>
  <div class="login">
    <div class="login-con">
        <loginDetail @setDialogVisible='setDialogVisible' @setTipDialogVisible='setTipDialogVisible' @setPassWordDialogVisible='setPassWordDialogVisible'></loginDetail>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="sucClassTip">
                    <div class="sucTipMessage">
                         <div class="TipOne">该账号非教师角色！</div>
                    </div>
                </div>
                <div class="operation">
                    <div class="sure" @click="sure">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="passWorddialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="sucClassTip">
                    <div class="sucTipMessage">
                         <div class="TipOne">账号或密码错误！</div>
                    </div>
                </div>
                <div class="operation">
                    <div class="sure" @click="passWordSure">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="tipDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%" @close="tipDialogClose">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="close" @click="close"></div>
                <div class="sucClassTip">
                    <div class="sucTipMessage">
                        <div class="TipOne">重置后在钉钉消息中查看密码</div>
                        <el-form ref="resetForm" :model="resetForm" :rules="resetRules" class="login-form">
                            <el-form-item prop="jobNo">
                                <el-input v-model="resetForm.jobNo" type="text" auto-complete="off" placeholder="请输入教师工号" ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="operation">
                    <div class="contOp" @click="close">取消</div>
                    <div class="reset" @click="reset">重置</div>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import loginDetail from "./components/login";
import { user } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "login",
  components: { loginDetail },
  data() {
    return {
      dialogVisible: false,
      tipDialogVisible: false,
      passWorddialogVisible: false,
      resetForm:{
          jobNo: ''
      },
      resetRules: {
        jobNo: [{ required: true, trigger: "change", message: "教师工号不能为空" }],
      },
    };
  },
  created() {},
  methods: {
    setDialogVisible(val){
        this.dialogVisible = val;
    },
    setTipDialogVisible(val){
        this.tipDialogVisible = val;
    },
    setPassWordDialogVisible(val){
        this.passWorddialogVisible = val;
    },
    sure(){
       this.dialogVisible = false
    },
    passWordSure(){
        this.passWorddialogVisible = false
    },
    close(){
      this.tipDialogVisible = false;
    },
    tipDialogClose(){
        this.$refs.resetForm.resetFields();
    },
    reset(){
        this.$refs.resetForm.validate((valid) => {
            if (valid) {
                user.resetPassWord(this.resetForm.jobNo, res => {
                    console.log(res)
                    if(res.status === 1) {
                        this.tipDialogVisible = false;
                        Message.success('密码已重置！');
                    }else {
                        Message.error(res.message);
                    }
                });
            }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url("../../assets/image/bg-login.png") center/cover no-repeat;
  .login-con {
    position: fixed;
    left: 50%;
    top: 45%;
    width: 899px;
    height: 561px;
    background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
    transform: translateY(-50%) translateX(-50%);
    overflow: hidden;
  }
    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 737px;
        height: 460px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 600px;
            height: 380px;
            background: #FFFCFD;
            border-radius: 130px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 360px;
                height: 100px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .close{
                position: absolute;
                left: 110%;
                top: -10%;
                width: 50px;
                height: 50px;
                background: url("../../assets/image/close.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
                cursor: pointer;
            }
            .sucClassTip{
                position: absolute;
                top: 24%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .TipOne{
                        letter-spacing:8px;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}
.operation{
    position: absolute;
    left: 50%;
    top: 75%;
    width: 500px;
    height: 110px;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    flex-flow:  row nowrap;
    align-items: center;
    justify-content: center;
    letter-spacing:3px;
    .sure{
        width: 200px;
        height: 60px;
        background: #2CBD74;
        border-radius: 42px;
        color: #FFFCFD;;
        line-height: 60px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
    }
    .reset{
        width: 150px;
        height: 50px;
        border: 1px solid #2CBD74;
        background: #2CBD74;
        border-radius: 42px;               
        color: #fff;
        line-height: 50px;
        font-size: 24px;
        cursor: pointer;
        text-align: center;
        margin-left: 60px;
    }
    .contOp{
        width: 150px;
        height: 50px;
        border: 1px solid #2CBD74;
        border-radius: 42px;               
        color: #2CBD74;
        line-height: 50px;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
    }
}
  .el-form-item {
    margin-bottom: 36px;
    width: 100%;
    width: 400px;
    /deep/.el-input {
      .el-input__inner {
        height: 55px;
        line-height: 55px;
        border-radius: 48px;
        padding-left: 40px;
        font-size: 16px;
        margin-left: 100px;
         &::placeholder {
            font-size: 16px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #939393;
        }
      }
    }
    /deep/ .el-form-item__error{
      font-size: 16px;
      left: 120px
    }
  }
/deep/ .el-dialog__headerbtn{
    display: none;
}
</style>
